<template>
  <div class="g_web">
    <h3 class="title">信义领域研发中心</h3>
    <div class="mapContainer">
      <div class="location_text">深圳市宝安区洪浪北二路26号</div>
      <div class="location_text">18129802502</div>
      <div class="work_time">邮箱：ouhong@situoyunyi.com</div>
    </div>
    <div class="footer">
      <div class="company">
        <p style="font-family: arial">&copy; 2019 思拓云医 版权所有</p>
      </div>
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/index.do"
        style="color: #f0d784;text-decoration: none;background: url(https://s1.ax1x.com/2018/09/29/ilmwIH.png) no-repeatleft center;"
        rel="nofollow"
        >渝ICP备2021001079号-1</a
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less" scoped>
.g_web {
  background: #f8f8f8;
  max-width: 512px;
}

.title {
  color: #333;
  background-color: white;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.mapContainer {
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.mapContainer .location_text {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.mapContainer .work_time {
  color: #999;
  font-size: 18px;
  font-weight: 400;
  line-height: 1rem;
  word-break: break-all;
  margin-top: 10px;
}

.footer {
  padding: 30px 20px;
  text-align: center;
}

.footer .company {
  color: #bfbfc3;
  font-size: 15px;
}
</style>
